<template>
 <div class="v_open_next_daili g-flex-column">
   <head-back :isBack="true">
    <template>
      <span>{{title}}</span>
    </template>
  </head-back>
  <div class="v-open-next-daili-content">
    <van-form label-width='7em' class="v-open-next-daili-form">
       <van-field v-if="form.id" readonly v-model="form.id"  name="代理ID"  label="代理ID：" placeholder="请填写代理ID：" :rules="[{ required: true, message: '请填写代理ID：' }]" />
       <van-field v-model="form.name"  name="代理姓名"  label="代理姓名：" placeholder="请填写代理姓名" :rules="[{ required: true, message: '请填写代理姓名' }]" />
       <van-field v-model="form.mobile" oninput="value=value.replace(/[^0-9.]/g,'')" name="代理手机号"  label="代理手机号：" placeholder="请填写代理手机号" :rules="[{ required: true, message: '请填写代理手机号' }]" />
       <van-field v-model="form.password"  name="代理登录密码"  label="代理登录密码：" placeholder="请填写代理登录密码" :rules="[{ required: true, message: '请填写代理登录密码' }]" />
       <van-field v-model="form.reach_rate"  name="代理抽点(%)"  label="代理抽点(%)：" placeholder="请填写代理抽点(%)" :rules="[{ required: true, message: '请填写代理抽点(%)' }]" />
    </van-form>
    <div class="v-open-next-daili-content-btn">
      <operation-button @clickItem="emitClickItem" :buttonObj="buttonObj"></operation-button>
    </div>
  </div>
 </div>
</template>

<script>
import HeadBack from '../../components/HeadBack.vue'
import OperationButton from '../../components/OperationButton.vue'
import { apiSaveAgent } from '@/utils/api.js'
export default {
  components: { HeadBack, OperationButton },
  data() {
    return {
      username: '',
      title: this.$route.query.id ? '编辑下级代理' : '开通下级代理',
      form: {
        id: this.$route.query.id ? this.$route.query.id : '',
        name: '',
        mobile: '',
        password: '',
        reach_rate: ''
      },
      buttonObj: {
        claSS: 'c-btn-default c-btn-border-radius',
        title: '确认提交',
        event: 'apiSaveAgentHandel'
      }
    }
  },
  methods: {
    async apiSaveAgentHandel() {
      this.$global.loadingShow = true
      const { success, data } = await apiSaveAgent(this.form)
      if(!success) return
      this.$toast(data.msg)
      this.$router.go(-1)
    },
    emitClickItem(event) {
      if(!event) return
      this[event]()
    },
    apiAddCashHandel() {}
  }
}
</script>

<style lang='scss'>
.v_open_next_daili {
  height: 100%;
  overflow: auto;
  .v-open-next-daili-content {
    flex: 1;
    overflow: auto;
    background: #F1F1F1;
    .v-open-next-daili-form {
      margin-top: 10px;
    }
    .v-open-next-daili-content-btn {
      margin-top: 30px;
    }
  }
}
</style>